import {Component, Provide, Vue} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import { CouponGetList } from "@/network/modules/common";
@Component({
  components: {
    common
  },
  computed: {
  }
})
export default class Coupon extends Vue {
  @Provide() CouponList:any = [];
  @Provide() titleIndex:number = 0
  @Provide() titleList:Array<{id:number,name:string}> = [{id:1,name:'未使用'},{id:0,name:'已失效'}]
  created(){
    const _this:any = this;
    this.GetCoupon(0);
  }
  typeChangeFn(index:number){
    const _this:any = this;
    _this.titleIndex = index
    _this.GetCoupon(index)
  }
  async GetCoupon(e:number){
    const _this:any = this;
    _this.loading = true
    const res = await CouponGetList({
      accountCouponStatus:e
    })
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { data, code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    _this.CouponList = data
  }
}
